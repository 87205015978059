<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <ReportViewer v-if="showreport" :json_data=jsondata :reportfilename="filename" :report_dir="reppath"></ReportViewer>

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
            <i class="fab-icon-open icon-filter3"></i>
            <i class="fab-icon-close icon-filter4"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->

      <FocusTrap>
        <vmodal name="filter_window" draggable=".p-1" :delay="100" :resizable="true" :width="575" height="auto" focusTrap="true" @opened="afterfilterwindow_visible">
          <form class="p-1">
            <h4 >Circulation Issue & Return Statement</h4>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Department</label>
              <div class="col-md-10">
                <select  ref="cmbDepartment" class="form-control" v-model="department_id">
                  <option value="0" selected>All</option>
                  <option v-for="dept in departments" :value="dept.id">{{dept.name}}</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Subject</label>
              <div class="col-md-10">
                <select  ref="cmbSubject" class="form-control" v-model="subject_id">
                  <option value="0" selected>All</option>
                  <option v-for="ledger in subjects" v-bind:value="ledger.id">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Section</label>
              <div class="col-md-10">
                <select  ref="cmbSection" class="form-control" v-model="section_id">
                  <option value="0" selected>All</option>
                  <option v-for="ledger in sections" v-bind:value="ledger.id">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Year / Batch</label>
              <div class="col-md-10">
                <select  ref="cmbAcademicyear" class="form-control" v-model="academicyear_id">
                  <option value="0" selected>All</option>
                  <option v-for="ledger in academicyears" v-bind:value="ledger.id">
                    {{ ledger.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-2 col-form-label font-weight-semibold">Issue From</label>
              <div class="col-md-4">
                <input  ref="txtFrom" class="form-control" type="date"  style="padding: 0px;" v-model="from_date">
              </div>
              <label class="col-md-2 col-form-label font-weight-semibold">Upto</label>
              <div class="col-md-4">
                <input class="form-control  text-center" type="date" v-model="upto_date">
              </div>
            </div>


            <div class="row align-self-end">
              <div class="col-md-12 text-right">
                <button class="btn btn-outline-success" @click="loadData">Show Report <i class="icon-printer ml-2" style="color: green;"></i> </button>
              </div>

            </div>

          </form>
        </vmodal>
      </FocusTrap>

    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment';
import {userService} from '@/store/auth-header.js'
import ReportViewer from "@/views/tools/ReportViewerByJson";
import { store } from '@/store/store.js'
import ReportFilterCard from '@/components/lms/ReportFilterCard'

export default {
  name: 'IssueReturnHistory',
  components: {
    ReportFilterCard,
    ReportViewer,
  },
  store,
  data()  {
    return {
      showreport: false,
      reppath: 'reports/circulation/issuereturn',
      filename: 'default.mrt',
      jsondata : {},
      from_date: moment().format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD'),
      departments : [],
      sections : [],
      subjects : [],
      academicyears:[],
      department_id : 0,
      section_id : 0,
      subject_id : 0,
      academicyear_id:0,
    }
  },
  mounted() {
    const self = this;
    self.$modal.show('filter_window');
    self.$data.jsondata = {"ok": true,"status": 200,"data": []};
    // self.$data.reppath = `${process.env.VUE_APP_ROOT_API}api/lms/reports/circulation/issue`;
    self.loadFilterData();
  },
  methods: {
    afterfilterwindow_visible () {
      this.$refs.cmbDepartment.focus();
    },
    showFilterWindow () {
      this.$modal.show('filter_window');
    },
    loadFilterData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: "cors",
        headers: userService.authHeader()
      };

      try {
        self.$data.departments = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/departments/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.departments = resp.data;
            }
          } else {
            swal({ title: "Oh noes!", text: resp.msg, type: "error" });
          }
        });

        self.$data.sections = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/sections/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.sections = resp.data;
            }
          } else {
            swal({ title: "Oh noes!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {

        });

        self.$data.subjects = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/subjects/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.subjects = resp.data;
            }
          } else {
            swal({ title: "Oh noes!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
        });

        self.$data.sections = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/sections/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.sections = resp.data;
            }
          } else {
            swal({ title: "Oh noes!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
        });

        // alert(`${process.env.VUE_APP_ROOT_API}api/lms/academicyear/after/1`);
        self.$data.academicyears = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/academicyears/after/1`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.academicyears = resp.data;
            }
          } else {
            swal({ title: "Oh noes!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          alert(err);
        });

      }catch (e) {
        alert(e);
      }
    },
    loadData(){
      const self = this;
      try {
        self.$data.showreport = false;
        self.$modal.hide('filter_window');

        const requestOptions = {
          method: 'GET',
          mode: "cors",
          headers: userService.authHeader()
        };

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        let filters = `from_date=${self.$data.from_date}&upto_date=${self.$data.upto_date}&department_id=${self.$data.department_id}&section_id=${self.$data.section_id}&subject_id=${self.$data.subject_id}&academicyear_id=${self.$data.academicyear_id}`;

        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/reports/circulation/issue?${filters}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.jsondata = resp;
              self.$data.showreport = true;
            }
          } else {
            swal({ title: "Oops!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        }).finally(function () {
          $('#mycard').unblock();
        });

      }catch (e) {
        alert(e);
      }

    },
  }
}
</script>

<style scoped>

</style>
